import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: string;
};

export type AgeCohortComparison = {
  __typename?: 'AgeCohortComparison';
  ageCohortSlug: Scalars['String'];
  bestOverallPercentile?: Maybe<Scalars['Float']>;
  overallPercentile?: Maybe<Scalars['Float']>;
  percentileByArea: Array<AreaPercentile>;
};

export type AreaLpi = {
  __typename?: 'AreaLpi';
  areaSlug: Scalars['String'];
  lpi?: Maybe<Scalars['Int']>;
};

export type AreaPercentile = {
  __typename?: 'AreaPercentile';
  areaSlug: Scalars['String'];
  percentile: Scalars['Float'];
};

export type AreaStats = {
  __typename?: 'AreaStats';
  attention: Scalars['Int'];
  best: Scalars['Int'];
  flexibility: Scalars['Int'];
  memory: Scalars['Int'];
  overall: Scalars['Int'];
  problem_solving: Scalars['Int'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  birthdate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<UiSettings>;
  subscriptionStatus?: Maybe<Scalars['String']>;
};

export type DemographicInput = {
  /** User's selected education */
  education?: InputMaybe<Scalars['String']>;
  /** User's selected gender */
  gender?: InputMaybe<Scalars['String']>;
  /** User's selected occupation */
  occupation?: InputMaybe<Scalars['String']>;
  /** User's selected referral source */
  referralSource?: InputMaybe<Scalars['String']>;
};

export type FitTest = {
  __typename?: 'FitTest';
  percentiles?: Maybe<Array<Maybe<GamePercentile>>>;
};

/** Represents a game. */
export type Game = {
  __typename?: 'Game';
  /** Area to which this game belongs. */
  areaSlug: Scalars['String'];
  /** Slug representing game. */
  slug: Scalars['String'];
};

export enum GameEngine {
  Cocos_2D = 'COCOS_2D',
  Cocos_3 = 'COCOS_3',
  Unity_2019 = 'UNITY_2019'
}

/** Represents the output from the client when a user finishes playing a game */
export type GameOutput = {
  __typename?: 'GameOutput';
  /** Time of row creation in epoch milliseconds (UTC). */
  createdAt: Scalars['Float'];
  /** Game Engine that generated the game output */
  engine: GameEngine;
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  /** Raw JSON string of game engine output */
  gameOutput: Scalars['String'];
  /** Provided game slug string from client */
  gameSlug: Scalars['String'];
  /** Id generated as MD5 hash from raw game ouput string */
  id: Scalars['ID'];
  /** Platform of Client sending request */
  platform: Platform;
  /** Time of mutation request received in epoch milliseconds (UTC). */
  receivedAt: Scalars['Float'];
  /** Id of user completing the game */
  userId: Scalars['ID'];
  /** Game variant of played game. Defaults to 'Regular' */
  variant: GameVariant;
};

export type GamePercentile = {
  __typename?: 'GamePercentile';
  ageCohortSlug: Scalars['String'];
  gamePlay: GamePlay;
  gameSlug: Scalars['String'];
  percentile?: Maybe<Scalars['Float']>;
};

export type GamePlay = {
  __typename?: 'GamePlay';
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  /** @deprecated Replaced with `FitTest` on `User`. */
  fitTestPercentile?: Maybe<Scalars['Int']>;
  game: Game;
  id: Scalars['Int'];
  /**
   * Level that user achieved for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelNext: Scalars['Int'];
  /**
   * Highest level that the Game allowed the user to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelPrevious: Scalars['Int'];
  /**
   * Level that user selected to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelSelected: Scalars['Int'];
  lpi: Scalars['Int'];
  /** FK - ID of the associated GameOutput */
  outputId: Scalars['String'];
  /** Platform on which game was played */
  platform: Platform;
  /** playerState, aka savedUserData -- game-specific settings from the output from the game */
  playerState?: Maybe<Scalars['String']>;
  /** Score resulting from this `GamePlay`. */
  score: Scalars['Int'];
  /** Current score's position relative to best score. */
  scorePosition: Scalars['Int'];
  /** Stat resulting from this `GamePlay`. Could be null if the `Game` does not have a stat. */
  stat?: Maybe<Scalars['Int']>;
  variant: GameVariant;
};

/** Generated upon completion of a game play */
export type GamePlayInput = {
  /** When game play was finished in epoch milliseconds (UTC). */
  finishedAt: Scalars['Float'];
  /** Stringified output from game engine */
  gameOutput: Scalars['String'];
  /** Game this play relates to. */
  gameSlug: Scalars['String'];
  /** Optional position for the game in the workout */
  position?: InputMaybe<Scalars['Int']>;
  variant?: InputMaybe<GameVariant>;
  /** Optional Unique identifier for the workout associated with this game play if this gameplay was associated with a workout */
  workoutId?: InputMaybe<Scalars['ID']>;
};

/** Represents the user's progress with respect to a particular game */
export type GameProgress = {
  __typename?: 'GameProgress';
  /**
   * Returns the `GamePlay` representing a `User`'s best stat for given `Platform` (via header) and `GameEngine`.
   * A `null` value signifies the `User` has never finished the `Game`.
   */
  bestStat?: Maybe<GamePlay>;
  /** `Game` data for this `GameProgress`. */
  game: Game;
  /**
   * The most recent play of this game for the current `Platform` and `GameEngine`.
   * A `null` value signifies the `User` has never played this `Game`.
   */
  lastGamePlay?: Maybe<GamePlay>;
  /**
   * Number of plays by a `User` for this [game](#game) to reach the next rank.
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  nextRankAt: Scalars['Int'];
  /**
   * `GameEngine` **and** `Platform` formatted stringified JSON (i.e. keys and values are specific to the `Platform` (via header) and `GameEngine`).
   * Can contain game-specific state (i.e. tower height or mute).
   * Includes canonical level data (`levelNext`).
   * Returns `null` if the `User` has never played this `Game` on any `Platform`.
   * Note that a non-null value is possible for a `Platform` a `User` has never played on due to canonical data (`level_next`). This can happen when a `User` plays on another platform.
   * # Example `UNITY_2021` Format for `contextual`
   * ```json
   * {
   *     "level_next": 1,
   *     "LumosLabs.Contextual.StoryView8": 1,
   *     "LumosLabs.Contextual.StoryView11": 1,
   *     "LumosLabs.Contextual.StoryView98": 1
   * }
   * ```
   * # Example `COCOS_2D` Format for `contextual`
   * ```json
   * {
   *     "user_level": 1,
   *     "savedUserData": {
   *         "recentShelves": [14],
   *         "previousViews": {
   *             "35": 1
   *         },
   *         "readingComp": 40
   *     }
   * }
   * # Example `COCOS_3` Format for `contextual`
   * ```json
   * {
   *     "user_level": 1,
   *     "savedUserData": {
   *         "recentShelves": [14],
   *         "previousViews": {
   *             "35": 1
   *         },
   *         "readingComp": 40
   *     }
   * }
   * ```
   */
  playerState?: Maybe<Scalars['JSONObject']>;
  /**
   * The number of game plays a user has completed after achieving their current rank.
   * For example the rank newcomer is achieved after completing 3 gameplays and the next rank is achieved after 8 game plays. So if a user has 5 gameplays, plays at rank is calculated as `5 - 3`.
   */
  playsAtRank: Scalars['Int'];
  /**
   * String representation of a `User`'s current rank for this [game](#game).
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  rank: Scalars['String'];
  /**
   * `GamePlay`s ordered by score (highest to lowest) for given `Platform` (via header) and `GameEngine`.
   * An empty array signifies the `User` has never finished the `Game`. Results are limited to 100.
   */
  topScores: Array<Maybe<GamePlay>>;
  /**
   * Total number of plays by a `User` for this [game](#game) across all `Platform`s and `GameEngine`s (i.e. canonical).
   * Primarily used for ranking purposes.
   */
  totalPlays: Scalars['Int'];
};


/** Represents the user's progress with respect to a particular game */
export type GameProgressTopScoresArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export enum GameVariant {
  FitTest = 'FitTest',
  Regular = 'Regular'
}

export type LpiSummary = {
  __typename?: 'LpiSummary';
  ageCohortComparison: AgeCohortComparison;
  bestOverallLpi?: Maybe<Scalars['Int']>;
  lpisByArea: Array<AreaLpi>;
  overallLpi?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds game play for a User with raw Cocos2d game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addCocos2dGameOutput: GameOutput;
  /** Adds game play for a User with raw Cocos 3 game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addCocos3GameOutput: GameOutput;
  /** Adds game play for a User with raw Unity game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addUnityGameOutput: GameOutput;
  /** Adds user input from the demographic onboarding survey */
  updateDemographics: User;
};


export type MutationAddCocos2dGameOutputArgs = {
  input: GamePlayInput;
};


export type MutationAddCocos3GameOutputArgs = {
  input: GamePlayInput;
};


export type MutationAddUnityGameOutputArgs = {
  input: GamePlayInput;
};


export type MutationUpdateDemographicsArgs = {
  input: DemographicInput;
};

export enum Platform {
  Mobile = 'mobile',
  Web = 'web'
}

export type Query = {
  __typename?: 'Query';
  GetCurrentUser?: Maybe<CurrentUser>;
  GetLpi: AreaStats;
  GetPercentile: AreaStats;
  _ageCohortUserById: User;
  _fitTestPercentile?: Maybe<GamePercentile>;
  _gamePlayById?: Maybe<GamePlay>;
  _gameUserById: User;
  _getGamePlaysByUserId: Array<GamePlay>;
  _getUserById: User;
  _lpiGamePlayById: GamePlay;
  /**
   * Fetch current user's game progress for one or more games.
   * @deprecated Supplanted by `gameProgressV2`
   */
  gameProgress: Array<GameProgress>;
  /**
   * Fetch current user's game progress for one or more games.
   * @deprecated Supplanted by `gamesProgress` on `User`
   */
  gameProgressV2: Array<GameProgress>;
  gameProgressV3: User;
  /** Fetch all games */
  games: Array<Maybe<Game>>;
  lpiSummary: LpiSummary;
  /** Fetch demographic survey options for a user */
  me: User;
  /** Fetch scripted workout history and status */
  scriptedWorkoutHistory: ScriptedWorkoutHistory;
  /** Fetch streak history for user. */
  streakHistory: StreakHistory;
  todaysWorkout: Workout;
  /** Education options */
  validEducation: Array<Scalars['String']>;
  /** Gender options */
  validGenders: Array<Scalars['String']>;
  /** Occupation options */
  validOccupations: Array<Scalars['String']>;
  /** Referral Source options */
  validReferralSources: Array<Scalars['String']>;
};


export type Query_AgeCohortUserByIdArgs = {
  id: Scalars['ID'];
};


export type Query_FitTestPercentileArgs = {
  gameSlug: Scalars['String'];
  platform: Platform;
  score: Scalars['Int'];
};


export type Query_GamePlayByIdArgs = {
  id: Scalars['Int'];
};


export type Query_GameUserByIdArgs = {
  id: Scalars['ID'];
};


export type Query_GetGamePlaysByUserIdArgs = {
  id: Scalars['ID'];
};


export type Query_GetUserByIdArgs = {
  id: Scalars['ID'];
};


export type Query_LpiGamePlayByIdArgs = {
  id: Scalars['Int'];
  platform: Platform;
  score: Scalars['Int'];
  slug: Scalars['String'];
  variant: GameVariant;
};


export type QueryGameProgressArgs = {
  games: Array<Scalars['String']>;
  variant?: InputMaybe<GameVariant>;
};


export type QueryGameProgressV2Args = {
  games: Array<Scalars['String']>;
  variant?: InputMaybe<GameVariant>;
};


export type QueryGameProgressV3Args = {
  engine?: InputMaybe<GameEngine>;
  slugs: Array<Scalars['String']>;
};


export type QueryTodaysWorkoutArgs = {
  knownGameSlugs: Array<Scalars['String']>;
};

export type ScriptedWorkoutHistory = {
  __typename?: 'ScriptedWorkoutHistory';
  /**
   * Reflects whether the user has finished the study.
   * If they started the last workout today, they must finish it to complete the study today.
   * If they started the last workout yesterday the study will be marked as complete today.
   */
  studyComplete: Scalars['Boolean'];
  /** Reflects whether the user's workout for today is complete */
  todaysWorkoutComplete: Scalars['Boolean'];
  totalScriptedWorkouts: Scalars['Int'];
  totalScriptedWorkoutsBegun: Scalars['Int'];
};

export type Streak = {
  __typename?: 'Streak';
  /** LocalDate streak ended (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  /** Length of streak in days. */
  length: Scalars['Int'];
  /** LocalDate streak began (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export type StreakHistory = {
  __typename?: 'StreakHistory';
  /** All time longest streak for user. */
  bestStreak?: Maybe<Streak>;
  /** Collection of streaks. Defaults to all-time history. Optional date range may be provided. */
  streaks: Array<Streak>;
};


export type StreakHistoryStreaksArgs = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type UiSettings = {
  __typename?: 'UISettings';
  game_sound_on?: Maybe<Scalars['Boolean']>;
  showSideNav?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  ageCohort: Scalars['String'];
  ageCohortSlug: Scalars['String'];
  education?: Maybe<Scalars['String']>;
  fitTest: FitTest;
  /**
   * The `GameProgress` for given `Game`s for this `User`.
   * Further filtered by given parameters (`gameSlug`, `engine` and `version`).
   * Platform is inferred by the `platform` header.
   */
  gamesProgress: Array<GameProgress>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  occupation?: Maybe<Scalars['String']>;
  referralSource?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type UserGamesProgressArgs = {
  engine?: InputMaybe<GameEngine>;
  slugs: Array<Scalars['String']>;
};

export type Workout = {
  __typename?: 'Workout';
  /** When this `Workout` was last changed (inclusive of `workoutGames`) */
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  /** Ordered list of recommended `Game`s for this `Workout` */
  workoutGames: Array<WorkoutGame>;
};

export type WorkoutGame = {
  __typename?: 'WorkoutGame';
  game: Game;
  /**
   * Last time `Game` was played as part of parent `Workout`.
   * `null` indicates the `Game` was never played for parent `Workout`.
   * Note that this `Game` could have been played independently of parent `Workout` (i.e. Games tab).
   */
  lastGamePlay?: Maybe<GamePlay>;
};

export type AddCocos3GameOutputMutationVariables = Exact<{
  input: GamePlayInput;
}>;


export type AddCocos3GameOutputMutation = { __typename?: 'Mutation', addCocos3GameOutput: { __typename?: 'GameOutput', id: string, finishedAt: number, receivedAt: number, createdAt: number, gameOutput: string, gameSlug: string, platform: Platform, engine: GameEngine, variant: GameVariant, userId: string } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', GetCurrentUser?: { __typename?: 'CurrentUser', id?: string | null, firstName?: string | null, birthdate?: string | null, subscriptionStatus?: string | null, settings?: { __typename?: 'UISettings', showSideNav?: boolean | null, game_sound_on?: boolean | null } | null } | null };

export type GetDemographicInputQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemographicInputQuery = { __typename?: 'Query', validEducation: Array<string>, validGenders: Array<string>, validOccupations: Array<string>, validReferralSources: Array<string> };

export type GetFitTestProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFitTestProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', ageCohort: string, fitTest: { __typename?: 'FitTest', percentiles?: Array<{ __typename?: 'GamePercentile', gameSlug: string, percentile?: number | null, gamePlay: { __typename?: 'GamePlay', outputId: string } } | null> | null } } };

export type GameProgressFragment = { __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, lastGamePlay?: { __typename?: 'GamePlay', outputId: string, finishedAt: number, score: number, stat?: number | null, playerState?: string | null, fitTestPercentile?: number | null } | null };

export type TopScoreFragment = { __typename?: 'GamePlay', score: number, finishedAt: number };

export type GetGameProgressQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String'];
  engine: GameEngine;
}>;


export type GetGameProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', gamesProgress: Array<{ __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, lastGamePlay?: { __typename?: 'GamePlay', outputId: string, finishedAt: number, score: number, stat?: number | null, playerState?: string | null, fitTestPercentile?: number | null } | null }> } };

export type LpiSummaryFragment = { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } };

export type GetLpisAndPercentilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLpisAndPercentilesQuery = { __typename?: 'Query', lpiSummary: { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } } };

export type GetScriptedWorkoutHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetScriptedWorkoutHistoryQuery = { __typename?: 'Query', scriptedWorkoutHistory: { __typename?: 'ScriptedWorkoutHistory', todaysWorkoutComplete: boolean, studyComplete: boolean, totalScriptedWorkouts: number, totalScriptedWorkoutsBegun: number } };

export type GetStreaksQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetStreaksQuery = { __typename?: 'Query', streakHistory: { __typename?: 'StreakHistory', streaks: Array<{ __typename?: 'Streak', startDate: string, endDate: string, length: number }>, bestStreak?: { __typename?: 'Streak', startDate: string, endDate: string, length: number } | null } };

export type WorkoutGameFragment = { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } };

export type GetTodaysWorkoutQueryVariables = Exact<{
  knownGameSlugs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetTodaysWorkoutQuery = { __typename?: 'Query', todaysWorkout: { __typename?: 'Workout', id: string, workoutGames: Array<{ __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }> } };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, username?: string | null, education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null, ageCohort: string } };

export type UpdateDemographicsMutationVariables = Exact<{
  input: DemographicInput;
}>;


export type UpdateDemographicsMutation = { __typename?: 'Mutation', updateDemographics: { __typename?: 'User', education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null } };

export const TopScoreFragmentDoc = gql`
    fragment TopScore on GamePlay {
  score
  finishedAt
}
    `;
export const GameProgressFragmentDoc = gql`
    fragment GameProgress on GameProgress {
  game {
    slug
    areaSlug
  }
  totalPlays
  topScores {
    ...TopScore
  }
  bestStat {
    stat
  }
  lastGamePlay {
    outputId
    finishedAt
    score
    stat
    playerState
    fitTestPercentile
  }
  rank
  playsAtRank
  nextRankAt
  playerState
}
    ${TopScoreFragmentDoc}`;
export const LpiSummaryFragmentDoc = gql`
    fragment LpiSummary on LpiSummary {
  overallLpi
  bestOverallLpi
  lpisByArea {
    lpi
    areaSlug
  }
  ageCohortComparison {
    ageCohortSlug
    overallPercentile
    bestOverallPercentile
    percentileByArea {
      percentile
      areaSlug
    }
  }
  updatedAt
}
    `;
export const WorkoutGameFragmentDoc = gql`
    fragment WorkoutGame on WorkoutGame {
  lastGamePlay {
    finishedAt
    id
  }
  game {
    slug
  }
}
    `;
export const AddCocos3GameOutputDocument = gql`
    mutation addCocos3GameOutput($input: GamePlayInput!) {
  addCocos3GameOutput(input: $input) {
    id
    finishedAt
    receivedAt
    createdAt
    gameOutput
    gameSlug
    platform
    engine
    variant
    userId
  }
}
    `;
export type AddCocos3GameOutputMutationFn = Apollo.MutationFunction<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>;

/**
 * __useAddCocos3GameOutputMutation__
 *
 * To run a mutation, you first call `useAddCocos3GameOutputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCocos3GameOutputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCocos3GameOutputMutation, { data, loading, error }] = useAddCocos3GameOutputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCocos3GameOutputMutation(baseOptions?: Apollo.MutationHookOptions<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>(AddCocos3GameOutputDocument, options);
      }
export type AddCocos3GameOutputMutationHookResult = ReturnType<typeof useAddCocos3GameOutputMutation>;
export type AddCocos3GameOutputMutationResult = Apollo.MutationResult<AddCocos3GameOutputMutation>;
export type AddCocos3GameOutputMutationOptions = Apollo.BaseMutationOptions<AddCocos3GameOutputMutation, AddCocos3GameOutputMutationVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  GetCurrentUser @client {
    id
    firstName
    birthdate
    subscriptionStatus
    settings {
      showSideNav
      game_sound_on
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDemographicInputDocument = gql`
    query GetDemographicInput {
  validEducation
  validGenders
  validOccupations
  validReferralSources
}
    `;

/**
 * __useGetDemographicInputQuery__
 *
 * To run a query within a React component, call `useGetDemographicInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemographicInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemographicInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemographicInputQuery(baseOptions?: Apollo.QueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
      }
export function useGetDemographicInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
        }
export type GetDemographicInputQueryHookResult = ReturnType<typeof useGetDemographicInputQuery>;
export type GetDemographicInputLazyQueryHookResult = ReturnType<typeof useGetDemographicInputLazyQuery>;
export type GetDemographicInputQueryResult = Apollo.QueryResult<GetDemographicInputQuery, GetDemographicInputQueryVariables>;
export const GetFitTestProgressDocument = gql`
    query GetFitTestProgress {
  me {
    ageCohort
    fitTest {
      percentiles {
        gameSlug
        gamePlay {
          outputId
        }
        percentile
      }
    }
  }
}
    `;

/**
 * __useGetFitTestProgressQuery__
 *
 * To run a query within a React component, call `useGetFitTestProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFitTestProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFitTestProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFitTestProgressQuery(baseOptions?: Apollo.QueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
      }
export function useGetFitTestProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
        }
export type GetFitTestProgressQueryHookResult = ReturnType<typeof useGetFitTestProgressQuery>;
export type GetFitTestProgressLazyQueryHookResult = ReturnType<typeof useGetFitTestProgressLazyQuery>;
export type GetFitTestProgressQueryResult = Apollo.QueryResult<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>;
export const GetGameProgressDocument = gql`
    query GetGameProgress($slugs: [String!]!, $engine: GameEngine!) {
  me {
    gamesProgress(slugs: $slugs, engine: $engine) {
      ...GameProgress
    }
  }
}
    ${GameProgressFragmentDoc}`;

/**
 * __useGetGameProgressQuery__
 *
 * To run a query within a React component, call `useGetGameProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameProgressQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *      engine: // value for 'engine'
 *   },
 * });
 */
export function useGetGameProgressQuery(baseOptions: Apollo.QueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
      }
export function useGetGameProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
        }
export type GetGameProgressQueryHookResult = ReturnType<typeof useGetGameProgressQuery>;
export type GetGameProgressLazyQueryHookResult = ReturnType<typeof useGetGameProgressLazyQuery>;
export type GetGameProgressQueryResult = Apollo.QueryResult<GetGameProgressQuery, GetGameProgressQueryVariables>;
export const GetLpisAndPercentilesDocument = gql`
    query GetLpisAndPercentiles {
  lpiSummary {
    ...LpiSummary
  }
}
    ${LpiSummaryFragmentDoc}`;

/**
 * __useGetLpisAndPercentilesQuery__
 *
 * To run a query within a React component, call `useGetLpisAndPercentilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLpisAndPercentilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLpisAndPercentilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLpisAndPercentilesQuery(baseOptions?: Apollo.QueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
      }
export function useGetLpisAndPercentilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
        }
export type GetLpisAndPercentilesQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesQuery>;
export type GetLpisAndPercentilesLazyQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesLazyQuery>;
export type GetLpisAndPercentilesQueryResult = Apollo.QueryResult<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>;
export const GetScriptedWorkoutHistoryDocument = gql`
    query GetScriptedWorkoutHistory {
  scriptedWorkoutHistory {
    todaysWorkoutComplete
    studyComplete
    totalScriptedWorkouts
    totalScriptedWorkoutsBegun
  }
}
    `;

/**
 * __useGetScriptedWorkoutHistoryQuery__
 *
 * To run a query within a React component, call `useGetScriptedWorkoutHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScriptedWorkoutHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScriptedWorkoutHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScriptedWorkoutHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetScriptedWorkoutHistoryQuery, GetScriptedWorkoutHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScriptedWorkoutHistoryQuery, GetScriptedWorkoutHistoryQueryVariables>(GetScriptedWorkoutHistoryDocument, options);
      }
export function useGetScriptedWorkoutHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScriptedWorkoutHistoryQuery, GetScriptedWorkoutHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScriptedWorkoutHistoryQuery, GetScriptedWorkoutHistoryQueryVariables>(GetScriptedWorkoutHistoryDocument, options);
        }
export type GetScriptedWorkoutHistoryQueryHookResult = ReturnType<typeof useGetScriptedWorkoutHistoryQuery>;
export type GetScriptedWorkoutHistoryLazyQueryHookResult = ReturnType<typeof useGetScriptedWorkoutHistoryLazyQuery>;
export type GetScriptedWorkoutHistoryQueryResult = Apollo.QueryResult<GetScriptedWorkoutHistoryQuery, GetScriptedWorkoutHistoryQueryVariables>;
export const GetStreaksDocument = gql`
    query GetStreaks($startDate: LocalDate) {
  streakHistory {
    streaks(startDate: $startDate) {
      startDate
      endDate
      length
    }
    bestStreak {
      startDate
      endDate
      length
    }
  }
}
    `;

/**
 * __useGetStreaksQuery__
 *
 * To run a query within a React component, call `useGetStreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreaksQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetStreaksQuery(baseOptions?: Apollo.QueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
      }
export function useGetStreaksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
        }
export type GetStreaksQueryHookResult = ReturnType<typeof useGetStreaksQuery>;
export type GetStreaksLazyQueryHookResult = ReturnType<typeof useGetStreaksLazyQuery>;
export type GetStreaksQueryResult = Apollo.QueryResult<GetStreaksQuery, GetStreaksQueryVariables>;
export const GetTodaysWorkoutDocument = gql`
    query GetTodaysWorkout($knownGameSlugs: [String!]!) {
  todaysWorkout(knownGameSlugs: $knownGameSlugs) {
    id
    workoutGames {
      ...WorkoutGame
    }
  }
}
    ${WorkoutGameFragmentDoc}`;

/**
 * __useGetTodaysWorkoutQuery__
 *
 * To run a query within a React component, call `useGetTodaysWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodaysWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodaysWorkoutQuery({
 *   variables: {
 *      knownGameSlugs: // value for 'knownGameSlugs'
 *   },
 * });
 */
export function useGetTodaysWorkoutQuery(baseOptions: Apollo.QueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
      }
export function useGetTodaysWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
        }
export type GetTodaysWorkoutQueryHookResult = ReturnType<typeof useGetTodaysWorkoutQuery>;
export type GetTodaysWorkoutLazyQueryHookResult = ReturnType<typeof useGetTodaysWorkoutLazyQuery>;
export type GetTodaysWorkoutQueryResult = Apollo.QueryResult<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  me {
    id
    username
    education
    gender
    occupation
    referralSource
    ageCohort
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UpdateDemographicsDocument = gql`
    mutation UpdateDemographics($input: DemographicInput!) {
  updateDemographics(input: $input) {
    education
    gender
    occupation
    referralSource
  }
}
    `;
export type UpdateDemographicsMutationFn = Apollo.MutationFunction<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;

/**
 * __useUpdateDemographicsMutation__
 *
 * To run a mutation, you first call `useUpdateDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDemographicsMutation, { data, loading, error }] = useUpdateDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDemographicsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>(UpdateDemographicsDocument, options);
      }
export type UpdateDemographicsMutationHookResult = ReturnType<typeof useUpdateDemographicsMutation>;
export type UpdateDemographicsMutationResult = Apollo.MutationResult<UpdateDemographicsMutation>;
export type UpdateDemographicsMutationOptions = Apollo.BaseMutationOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;
export const namedOperations = {
  Query: {
    GetCurrentUser: 'GetCurrentUser',
    GetDemographicInput: 'GetDemographicInput',
    GetFitTestProgress: 'GetFitTestProgress',
    GetGameProgress: 'GetGameProgress',
    GetLpisAndPercentiles: 'GetLpisAndPercentiles',
    GetScriptedWorkoutHistory: 'GetScriptedWorkoutHistory',
    GetStreaks: 'GetStreaks',
    GetTodaysWorkout: 'GetTodaysWorkout',
    GetUser: 'GetUser'
  },
  Mutation: {
    addCocos3GameOutput: 'addCocos3GameOutput',
    UpdateDemographics: 'UpdateDemographics'
  },
  Fragment: {
    GameProgress: 'GameProgress',
    TopScore: 'TopScore',
    LpiSummary: 'LpiSummary',
    WorkoutGame: 'WorkoutGame'
  }
}